import { Download, Edit } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Icon, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { lighten } from '@mui/material/styles';
import { DataGrid, DataGridProps, GridColTypeDef, GridEventListener, GridFilterModel, GridRenderCellParams, GridSortModel, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import "./PMTable.scss";

export interface PMTableProps extends DataGridProps {
    grid?: number;
    Onclick?: Function;
    showSerialNumber?: boolean;
    showExportOptions?: boolean;
    headerColor?: string;
    tableHeaderColor?: string;
    rows: any
    columns: any[],
    showQuickFilter: boolean,
    tableApi: any,
    updateTable: Function
    rowColor: any
    rowColorField: any
}

const locale = 'en-IN'
const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'INR',
});

const currencyType: GridColTypeDef = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => value ? currencyFormatter.format(value) : '--',
    cellClassName: 'font-tabular-nums',
}


const dateType: GridColTypeDef = {
    valueFormatter: ({ value }) => value?format(parseISO(value), "dd-MMM-yyyy"): ''
}

const dateTimeType: GridColTypeDef = {
    valueFormatter: ({ value }) => value?format(parseISO(value), "dd-MMM-yyyy h:m a"): ''
}

const iconType: GridColTypeDef = {
    type: 'string',
    renderCell: (params: GridRenderCellParams<string>) => (
        <Icon>{params.value}</Icon>
    )
}

const linkType: GridColTypeDef = {
    type: 'actions',
    renderCell: (params: GridRenderCellParams<string>) => (
        <Link href={`${params.value}`} underline="hover">{params.field}</Link>
    )
}

const editType: GridColTypeDef = {
    type: 'actions',
    renderCell: (params: any) => {
        const url = params.colDef.url ? `${params.colDef.url}/${params.row.id}` : `${params.row.id}`
        if(params?.colDef?.condition && params?.colDef?.condition?.length){
            if(params.row[params.colDef.condition[0]]==params.colDef.condition[1]){
                return <Link href={url} sx={{"white-space": "break-spaces","text-align": "center"}} underline="hover">{params.field ? params.field : <Edit />}</Link>

            }else {
                return <></>
            }
        }
        return (<Link href={url} sx={{"white-space": "break-spaces","text-align": "center"}} underline="hover">{params.field ? params.field : <Edit />}</Link>)
    }
}


const avatarType: GridColTypeDef = {
    align: 'center',
    renderCell: (params: GridRenderCellParams<string>) => (
        <Avatar alt="" src={params.value} />
    )
}

const chipType: GridColTypeDef = {
    align: 'center',
    renderCell: (params: any) => {
        let returnChip = <Chip color={"primary"} label={params?.value}/>
        if(params?.colDef?.condition && params?.colDef?.condition?.length){
            params?.colDef?.condition.forEach((conditions) => {
                if(Array.isArray(conditions["rule"])){
                    if(params?.value>=conditions["rule"][0] && params.value<conditions["rule"][1]){
                        returnChip =  <Chip color={conditions.color} label={params?.value} />
                    }
                }else if(params?.value==conditions["rule"]){
                    returnChip = <Chip color={conditions.color} label={params?.value}/>
                }
            })
        }
        return returnChip
    }
}

const multiLineType: GridColTypeDef = {
    renderCell: (params: GridRenderCellParams<string>) => {
        if(params.value){
            const data = params.value.split("\n")
            return (
                <>
                {data.map((element, index) => {
                    return (
                        <>{element}<br></br></>
                    )
                })}
                </>
            )
        }
        return '--'
    }
}

const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
        }}
      >
        <GridToolbarQuickFilter size="medium"/>
      </Box>
    );
}


const PMTable = (props: PMTableProps & DataGridProps ) => {
    const {showSerialNumber, showExportOptions, headerColor, rows, columns, tableHeaderColor, showQuickFilter, updateTable, tableApi, ...baseProps} = props;

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [rowCountState, setRowCountState] = useState(0)
    let rowHeight = null
    const [anchorEls, setAnchorEls] = useState<Object>({});
    const [queryOptions, setQueryOptions] = useState({});

    const handleDownloadClick = (id, event) => {
        anchorEls[id] = event.target
        setAnchorEls({ ...anchorEls});
    }
    const handleDownloadClose = (id, event) => {
        anchorEls[id] = null
        setAnchorEls({ ...anchorEls});
    }

    if(rows?.length){
        rows.map((ele, index) => {
            if(!ele["id"]){
                ele["id"] =  ''+index
            }
        })
    }
    useEffect(() => {
        if(updateTable){
            updateTable({page: page+1, size: pageSize, ...queryOptions})
        }
    }, [page, pageSize, queryOptions])

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        // Here you save the data you need from the filter model
        setQueryOptions({ ...filterModel });
    }, []);

    const buttonType: GridColTypeDef = {
        type: 'actions',
        renderCell: (params: any) => {
            if(params?.colDef?.condition && params?.colDef?.condition?.length){
                if(params.row[params.colDef.condition[0]]==params.colDef.condition[1]){
                    return <Button onClick={() => params.colDef?.onclick({id: params.row.id})}>{params.field}</Button>
                }else {
                    return <></>
                }
            }
            return (<Button onClick={() => params.colDef?.onclick({id: params.row.id})}>{params.field}</Button>)
        }
    }
    const downloadType: GridColTypeDef = {
        type: 'actions',
        renderCell: (params: GridRenderCellParams<string>) => {
            if(Array.isArray(params.value)){
                if(params.value.length){
                return (<><IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    color='primary'
                    onClick={e => handleDownloadClick(params.id, e)}
                >
                    <Download />
                </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEls[params.id]}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        keepMounted
                        open={Boolean(anchorEls[params.id])}
                        onClose={e => handleDownloadClose(params.id, e)}
                    >
                        {params.value.map(docs =>
                            <MenuItem>
                                <Link href={docs} target="_blank" download underline="hover">Download</Link>
                            </MenuItem>
                        )}
                    </Menu></>)
                } else {
                    return <></>
                }
            } else {
                return params.value ? <Link href={params.value} target="_blank" download underline="hover"><Download /></Link>:<></>
            }
        }
    }

    let columnDefs = columns.map((colDef) => {
        let typeDef = {}
        switch (colDef.type) {
            case "date":
                typeDef = dateType
                break
            case "datetime":
                typeDef = dateTimeType
                break
            case "currency":
                typeDef = currencyType
                break
            case "icon":
                typeDef = iconType
                break
            case "button":
                typeDef = buttonType
                break
            case "link":
                typeDef = linkType
                break
            case "edit":
                typeDef = editType
                break
            case "download":
                typeDef = downloadType
                break
            case "multiline":
                typeDef = multiLineType
                rowHeight = 'auto'
                break
            case "avatar":
                typeDef = avatarType
                break
            case "chip":
                typeDef = chipType
                break
            }

        return {
            ...colDef,
            ...typeDef,
            flex: colDef.flex ? colDef.flex: 1,
            cellClassName:colDef.highlight?'custom-highlight':''
        }
    })

    if (showSerialNumber) {
        columnDefs.unshift({
            field: "id",
            headerName: "No.",
            filterable: false,
            sortable: false,
            flex: 0,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
        });
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{justifyContent: "right"}}>
                  {showExportOptions && <GridToolbarExport />}
                  {showQuickFilter && <QuickSearchToolbar />}
            </GridToolbarContainer>
        );
    }

    let datagridAdditionalProps = {}
    if (showExportOptions || showQuickFilter) {
        datagridAdditionalProps["components"] = {
            Toolbar: CustomToolbar,
        }
    }
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        var sort_key = ''
        if(sortModel[0]["sort"]){
            if(sortModel[0]["sort"]==='desc'){
                sort_key = sortModel[0]['field']
            }else{
                sort_key = `-${sortModel[0]['field']}`
            }
        }
        setQueryOptions({ order_by: sort_key });
    }, []);

    datagridAdditionalProps["sx"] = {
        border: 0,
        "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
            display: "none"
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${tableHeaderColor}.main`,
            color: `${tableHeaderColor}.contrastText`,
            fontSize: 16
        },
        '& .MuiDataGrid-cell': {
            minHeight:'52px !important'
        },
        '& .custom-highlight': {
            fontWeight:"600"
        },
        '& .MuiDataGrid-sortIcon': {
            color: `${tableHeaderColor}.contrastText`,
        },
    }
    const getCustomRowClass = (params) => {
        let className = ``
        if(props.rowColor && props.rowColorField){
            props.rowColor.forEach((condition) => {
                if(Array.isArray(condition["rule"])){
                    if(params.row[props.rowColorField]>=condition["rule"][0] && params.row[props.rowColorField]<condition["rule"][1]){
                        className = `customRows-${condition["color"]}`
                    }
                }else if(params.row[props.rowColorField]==condition["rule"]){
                    className = `customRows-${condition["color"]}`
                }
            })
        }
        return className
    }

    if(props.rowColor){
        props.rowColor.forEach((condition) => {
            datagridAdditionalProps["sx"][`& .customRows-${condition['color']}`] = {
                backgroundColor: `${condition.color}.main`,
                color: `${condition.color}.contrastText`,
                '&:hover':{
                    backgroundColor: lighten(theme.palette[`${condition.color}`].main, 0.5)
                } 
            }
        })
    }

    const handleRowClick: GridEventListener<"rowClick"> = (params) => {
        if (props?.Onclick) {
            props?.Onclick(params.row);
        }
    };

    return (
        <Box style={{ height: "auto", overflow: "auto", width: "100%" }}>
            {!tableApi?<DataGrid
                disableSelectionOnClick={true}
                getRowHeight={() => rowHeight}
                getEstimatedRowHeight={() => 200}
                onRowClick={handleRowClick}
                disableColumnMenu={true}
                columns={columnDefs} rows={rows}
                autoHeight={true}
                {...datagridAdditionalProps}
                {...baseProps}
                getRowClassName={(params) => getCustomRowClass(params)}
            />
            :<DataGrid
                disableSelectionOnClick={true}
                getRowHeight={() => rowHeight}
                getEstimatedRowHeight={() => 200}
                onRowClick={handleRowClick}
                disableColumnMenu={true}
                columns={columnDefs} rows={tableApi?.items || []}
                rowCount={tableApi?.total || 0}
                autoHeight={true}
                {...datagridAdditionalProps}
                {...baseProps}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                // filterMode="server"
                // onFilterModelChange={onFilterChange}
            />
}
        </Box>
    );
};

PMTable.defaultProps = {
    showSerialNumber: false,
    showExportOptions: false,
    headerColor: "grey.100",
    tableHeaderColor: "primary",
    showQuickFilter: true
};

export default PMTable;
