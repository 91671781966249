// @ts-nocheck

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { errorSerializer } from "../helperFn/errorSerializer";
import { FormWidget, PMGrid } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { usePolicyDetailsQuery } from "../store/policyDetailsSlice";

import { usePolicyUpdateMutation } from "../store/policyUpdateSlice";

function EditPolicy() {
    const policyDetailsData = useSelector((states) => states?.appStore?.policyDetailsData);
    const [policyDetailsSkip, setpolicyDetailsSkip] = useState(true);
    const [policyDetailsParams, setpolicyDetailsParams] = useState({});
    const policyDetailsQuery = usePolicyDetailsQuery(policyDetailsParams, { skip: policyDetailsSkip });
    const policyUpdateMutation = usePolicyUpdateMutation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const productVariantListData = useSelector((states) => states?.appStore?.productVariantListData);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const pocreated = (data) => {
        history("/policies/");
    };

    const showSuccessAlert = (data) => {
        enqueueSnackbar("Policy Updated", { variant: "success" });
    };

    const policyUpdate = async (data) => {
        var apiData = data;

        if (apiData) {
            await policyUpdateMutation[0]({ id: apiData["id"], data: { ...apiData } }).unwrap();
        }
    };

    const policyUpdateSuscess = (data) => {
        showSuccessAlert(data);
        pocreated(data);
    };
    useEffect(() => {
        if (policyUpdateMutation[1].isSuccess) {
            policyUpdateSuscess(policyUpdateMutation[1]);
        }
        if (policyUpdateMutation[1].isError) {
            enqueueSnackbar(errorSerializer(policyUpdateMutation[1]), { variant: "error" });
        }
    }, [policyUpdateMutation]);

    const policyUpdateApi = (data) => {
        policyUpdate(data);
    };

    const savepolicyDetailsData = (data) => {
        var formatedData = {
            policyDetailsData: data?.data || data,
        };
        dispatch(setStore(formatedData));
    };

    const policyDetailsSuccess = (data) => {
        savepolicyDetailsData(data);
    };
    useEffect(() => {
        if (policyDetailsQuery.isSuccess) {
            policyDetailsSuccess(policyDetailsQuery);
        } else if (policyDetailsQuery.isError) {
            policyDetailsSuccess(null);
        }
    }, [policyDetailsQuery]);
    const policyDetails = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setpolicyDetailsParams({ id: id });
        setpolicyDetailsSkip(false);
    };

    useEffect(() => {
        policyDetails();
    }, [id]);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Edit Policy" }}>
                <PMGrid container={true} xs={12}>
                    <FormWidget
                        direction="column"
                        header="Create Stock Out Entry"
                        isLoading={policyUpdateMutation[1].isLoading}
                        defaultValues={policyDetailsData || []}
                        Onsubmit={policyUpdateApi}
                        fieldsets={[
                            {
                                direction: "row",
                                header: "Customer Details",
                                grid: 3,
                                fields: [
                                    { label: "Customer Name", name: "customer_name", type: "text" },
                                    { label: "Mobile Number", name: "customer_mobile_number", type: "text", required: true },
                                    { label: "Email", name: "customer_email", type: "text", required: true },
                                    { label: "Address", name: "customer_address", type: "text", required: true },
                                    { label: "City", name: "customer_city", type: "text" },
                                    { label: "State", name: "customer_state", type: "text" },
                                    { label: "Pincode", name: "pincode", type: "text" },
                                    { label: "Business Type", name: "business_type", type: "text" },
                                ],
                            },
                            {
                                direction: "row",
                                grid: 3,
                                header: "Vehicle Details",
                                fields: [
                                    { label: "Registration No", name: "vehicle_registration_number", type: "text" },
                                    { label: "Make", name: "vehicle_make", type: "text" },
                                    { label: "Model", name: "vehicle_model", type: "text" },
                                    { label: "Variant", name: "vehicle_variant", type: "text" },
                                    { label: "Vehicle Class", name: "vehicle_class", type: "text" },
                                    { label: "Vehicle Subclass", name: "vehicle_subclass", type: "text" },
                                    { label: "No of Wheels", name: "number_of_wheels", type: "text" },
                                    { label: "Seating Capacity", name: "seating_capicity", type: "text" },
                                    { label: "Carrying Capacity", name: "carrying_capicity", type: "text" },
                                    { label: "Cubic Capacity", name: "cubic_capicity", type: "text" },
                                    { label: "Vehicle Carrier Type", name: "vehicle_carrier_type", type: "text" },
                                    { label: "Fuel Type", name: "fuel_type", type: "text" },
                                    { label: "Gross Vehicle Weight", name: "gross_vehicle_weight", type: "text" },
                                    { label: "Sum Insured", name: "sum_insured", type: "text" },
                                    { label: "Manufacture Date", name: "date_of_manufacture", type: "text" },
                                    { label: "Ncb", name: "no_claim_bonus", type: "text" },
                                    {
                                        label: "Vehicle Registration Status",
                                        name: "vehicle_registrasion_status",
                                        type: "text",
                                    },
                                    {
                                        label: "Vehicle Registration Date",
                                        name: "date_of_registration",
                                        type: "text",
                                    },
                                    {
                                        label: "Vehicle Registration Number",
                                        name: "vehicle_registration_number",
                                        type: "text",
                                    },
                                    {
                                        label: "Name of Registration Authority",
                                        name: "name_of_registration_authority",
                                        type: "text",
                                    },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Premium Details",
                                grid: 3,
                                fields: [
                                    { label: "Basic OD Premium", name: "basic_own_damage_premium", type: "text" },
                                    { label: "OD Net Premium", name: "od_net_premium", type: "text" },
                                    { label: "Basic TP Premium", name: "basic_tp_premium", type: "text" },
                                    { label: "TP Net Premium", name: "tp_net_premium", type: "text" },
                                    { label: "GST", name: "gst", type: "text" },
                                    { label: "Gross Premium", name: "gross_premium", type: "text" },
                                    { label: "Net Premium", name: "net_premium", type: "text" },
                                    { label: "GST Rate", name: "gst_rate", type: "text" },
                                    { label: "Owner Driver", name: "pa_owner_driver_cover_premium", type: "text" },
                                    { label: "Owner Driver Per", name: "owner_driver_per", type: "text" },
                                    { label: "Roadside Assistance", name: "roadside_assistance_cover_premium", type: "text" },
                                    { label: "Zero Depreciation Cover", name: "zero_depreciation_cover_premium", type: "text" },
                                    { label: "Engine Protection Cover", name: "engine_protection_cover_premium", type: "text" },
                                    { label: "Stamp Duty", name: "stamp_duty", type: "text" },
                                    { label: "Total Broker Payin rate", name: "total_broker_payin_rate", type: "text" },
                                    { label: "Broker Payin rate for TP", name: "broker_payin_rate_for_tp", type: "text" },
                                    { label: "Broker Payin rate for OD", name: "broker_payin_rate_for_od", type: "text" },
                                    { label: "Broker Reward Rate", name: "broker_reward_rate", type: "text" },
                                    { label: "Broker Reward Rate for TP", name: "broker_reward_rate_for_tp", type: "text" },
                                    { label: "Broker Reward Rate based on", name: "broker_reward_rate_based_on", type: "text" },
                                    { label: "Expected payout Rate", name: "expected_payout_rate", type: "text" },
                                    { label: "Expected payout Rate on TP", name: "expected_payout_rate_on_tp", type: "text" },
                                    { label: "Refferal Rate", name: "refferal_rate", type: "text" },
                                    { label: "Refferal Rate for TP", name: "refferal_rate_for_tp", type: "text" },
                                    { label: "Posp Payout Rate", name: "posp_payout_rate", type: "text" },
                                    { label: "Posp Payout Rate for TP", name: "posp_payout_rate_for_tp", type: "text" },
                                    { label: "Posp Payout Based on", name: "posp_payout_based_on", type: "text" },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Policy Details",
                                grid: 3,
                                fields: [
                                    { label: "Insurer", name: "insurer_name", type: "text" },
                                    { label: "Insurer Branch Code", name: "insurer_branch_id", type: "text" },
                                    { label: "Policy Type", name: "policy_type", type: "text" },
                                    { label: "Policy No", name: "policy_number", type: "text" },
                                    { label: "Previous Policy No", name: "previous_policy_number", type: "text" },
                                    { label: "Start Date", name: "policy_start_date", type: "date" },
                                    { label: "End Date", name: "policy_end_date", type: "date" },
                                    { label: "Policy Issue Date", name: "policy_issue_date", type: "date" },
                                    { label: "Policy Receive Date", name: "policy_receive_date", type: "date" },
                                    { label: "Broker Branch Code", name: "broker_branch_id", type: "text" },
                                    { label: "Pol Recieved Format", name: "policy_recieved_format", type: "text" },
                                    { label: "Broker Biz Type", name: "broker_business_type", type: "text" },
                                    { label: "Insurer Biz Type", name: "insurer_business_type", type: "text" },
                                    { label: "Policy Status", name: "policy_status", type: "text" },
                                    { label: "RTO CIty", name: "rto_city", type: "text" },
                                    { label: "RTO State", name: "rto_state", type: "text" },
                                    { label: "RTO Code", name: "rto_code", type: "text" },

                                    { label: "Policy Status", name: "policy_status", type: "text" },
                                    { label: "Policy Term", name: "policy_term", type: "text" },
                                    { label: "OD Term", name: "od_term", type: "text" },
                                    { label: "TP Term", name: "tp_term", type: "text" },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Payment Details",
                                grid: 3,
                                fields: [
                                    { label: "Mode", name: "mode", type: "text" },
                                    { label: "Transaction Date", name: "transaction_date", type: "date" },
                                    { label: "Receipt No", name: "receipt_number", type: "text" },
                                    { label: "Premium Receipt No", name: "premium_receipt_number", type: "text" },
                                    { label: "Premium Receipt Date", name: "premium_receipt_date", type: "date" },
                                    { label: "Sourcing Date", name: "bus_prop_date", type: "date" },
                                    { label: "Pay At", name: "pay_at", type: "text" },
                                    { label: "Transaction No", name: "transaction_number", type: "text" },
                                    { label: "Payment Term", name: "payment_term", type: "text" },
                                ],
                            },
                            {
                                direction: "row",
                                header: "Bussiness Details",
                                grid: 3,
                                fields: [
                                    { label: "Ref Pos Misp", name: "ref_pos_misp", type: "text" },
                                    { label: "Rm Code", name: "rm_id", type: "text" },
                                    { label: "Solicit Code", name: "solicit_id", type: "text" },
                                    { label: "Csc Code", name: "csc_code", type: "text" },
                                    { label: "Tc idCode", name: "tc_code", type: "text" },
                                    { label: "Ref Pos Misp Code", name: "ref_pos_misp_code", type: "text" },
                                    { label: "Campaign Name", name: "policy_source_campaigning_name", type: "text" },
                                    { label: "Remarks", name: "policy_remarks", type: "text" },
                                ],
                            },
                        ]}
                    ></FormWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default EditPolicy;
