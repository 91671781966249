import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const countSuccessDateSlice = createApi({
    reducerPath: "countSuccessDate",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://policy-parsing.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        countSuccessDate: builder.query({
            query: (data) => ({ url: "/policy-inward/api/v1/pdf/list", params: data, method: "GET" }),
        }),
    }),
});

export const { useCountSuccessDateQuery } = countSuccessDateSlice;
