// @ts-nocheck

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailWidget, FormWidget, MetricWidget, PMButton, PMGrid, TableWidget } from "../lib";
import { setStore } from "../store/appSlice";
import { MenuWidget } from "./MenuWidget";

import { useTotalPremiumTypeQuery } from "../store/totalPremiumTypeSlice";

import { useCountSuccessProcessedQuery } from "../store/countSuccessProcessedSlice";

import { useCountAllProcessedQuery } from "../store/countAllProcessedSlice";

import { useCountPremiumTypeQuery } from "../store/countPremiumTypeSlice";

import { useCountAllProcessedDateQuery } from "../store/countAllProcessedDateSlice";
import { default as countAllProcessedDateFormatter } from "../transformations/countAllProcessedDateFormatter";
import { default as countPremiumTypeFormatter } from "../transformations/countPremiumTypeFormatter";
import { default as countSuccessProcessedFormatter } from "../transformations/countSuccessProcessedFormatter";
import { default as policyListListFormatter } from "../transformations/policyListListFormatter";
import { default as totalPremiumTypeFormatter } from "../transformations/totalPremiumTypeFormatter";
function Home() {
    const sumPremiumTypeData = useSelector((states) => states?.appStore?.sumPremiumTypeData);
    const insurerData = useSelector((states) => states?.appStore?.insurerData);
    const tableDataSucc = useSelector((states) => states?.appStore?.tableDataSucc);
    const [totalPremiumTypeSkip, settotalPremiumTypeSkip] = useState(true);
    const [totalPremiumTypeParams, settotalPremiumTypeParams] = useState({});
    const totalPremiumTypeQuery = useTotalPremiumTypeQuery(totalPremiumTypeParams, { skip: totalPremiumTypeSkip });
    const [countSuccessProcessedSkip, setcountSuccessProcessedSkip] = useState(true);
    const [countSuccessProcessedParams, setcountSuccessProcessedParams] = useState({});
    const countSuccessProcessedQuery = useCountSuccessProcessedQuery(countSuccessProcessedParams, {
        skip: countSuccessProcessedSkip,
    });
    const [countAllProcessedSkip, setcountAllProcessedSkip] = useState(true);
    const [countAllProcessedParams, setcountAllProcessedParams] = useState({});
    const countAllProcessedQuery = useCountAllProcessedQuery(countAllProcessedParams, { skip: countAllProcessedSkip });
    const [countPremiumTypeSkip, setcountPremiumTypeSkip] = useState(true);
    const [countPremiumTypeParams, setcountPremiumTypeParams] = useState({});
    const countPremiumTypeQuery = useCountPremiumTypeQuery(countPremiumTypeParams, { skip: countPremiumTypeSkip });
    const [countAllProcessedDateSkip, setcountAllProcessedDateSkip] = useState(true);
    const [countAllProcessedDateParams, setcountAllProcessedDateParams] = useState({});
    const countAllProcessedDateQuery = useCountAllProcessedDateQuery(countAllProcessedDateParams, {
        skip: countAllProcessedDateSkip,
    });
    const dispatch = useDispatch();
    const [filterState, setfilterState] = useState(false);

    const countAllProcessedDateListData = (data) => {
        var formatedData = {
            ...countAllProcessedDateFormatter(data?.data || data, "insurerData"),
        };
        dispatch(setStore(formatedData));
    };

    const countAllProcessedDateSuccess = (data) => {
        countAllProcessedDateListData(data);
    };
    useEffect(() => {
        if (countAllProcessedDateQuery.isSuccess) {
            countAllProcessedDateSuccess(countAllProcessedDateQuery);
        } else if (countAllProcessedDateQuery.isError) {
            countAllProcessedDateSuccess(null);
        }
    }, [countAllProcessedDateQuery]);
    const countAllProcessedDate = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountAllProcessedDateParams({
            metric: "id,net_premium,insurer_name",
            function: "count,sum,",
            dimension: "insurer_name",
            created_at__gte: data ? data["start_date"] : "start_date",
            created_at__lte: data ? data["end_date"] : "end_date",
        });
        setcountAllProcessedDateSkip(false);
    };

    const countPremiumTypeListData = (data) => {
        var formatedData = {
            ...countPremiumTypeFormatter(data?.data || data, "sumPremiumTypeData"),
        };
        dispatch(setStore(formatedData));
    };

    const countPremiumTypeSuccess = (data) => {
        countPremiumTypeListData(data);
    };
    useEffect(() => {
        if (countPremiumTypeQuery.isSuccess) {
            countPremiumTypeSuccess(countPremiumTypeQuery);
        } else if (countPremiumTypeQuery.isError) {
            countPremiumTypeSuccess(null);
        }
    }, [countPremiumTypeQuery]);
    const countPremiumType = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountPremiumTypeParams({
            metric: "id,transaction_amount,policy_type",
            function: "count,sum,",
            dimension: "policy_type",
            created_at__gte: data ? data["start_date"] : "start_date",
            created_at__lte: data ? data["end_date"] : "end_date",
        });
        setcountPremiumTypeSkip(false);
    };

    const policyUpdateApi = (data) => {
        countPremiumType(data);

        countAllProcessedDate(data);
    };

    const setFilterState = () => {
        setfilterState(!filterState);
    };

    const filterClick = (data) => {
        setFilterState(data);
    };

    const countAllProcessedListData = (data) => {
        var formatedData = {
            ...policyListListFormatter(data?.data || data, "insurerData"),
        };
        dispatch(setStore(formatedData));
    };

    const countAllProcessedSuccess = (data) => {
        countAllProcessedListData(data);
    };
    useEffect(() => {
        if (countAllProcessedQuery.isSuccess) {
            countAllProcessedSuccess(countAllProcessedQuery);
        } else if (countAllProcessedQuery.isError) {
            countAllProcessedSuccess(null);
        }
    }, [countAllProcessedQuery]);
    const countAllProcessed = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountAllProcessedParams({
            metric: data ? data["id,net_premium,insurer_name"] : "id,net_premium,insurer_name",
            function: data ? data["count,sum,"] : "count,sum,",
            dimension: data ? data["insurer_name"] : "insurer_name",
        });
        setcountAllProcessedSkip(false);
    };

    const countSuccessProcessedListData = (data) => {
        var formatedData = {
            ...countSuccessProcessedFormatter(data?.data || data, "tableDataSucc"),
        };
        dispatch(setStore(formatedData));
    };

    const countSuccessProcessedSuccess = (data) => {
        countSuccessProcessedListData(data);
    };
    useEffect(() => {
        if (countSuccessProcessedQuery.isSuccess) {
            countSuccessProcessedSuccess(countSuccessProcessedQuery);
        } else if (countSuccessProcessedQuery.isError) {
            countSuccessProcessedSuccess(null);
        }
    }, [countSuccessProcessedQuery]);
    const countSuccessProcessed = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        setcountSuccessProcessedParams({ size: data ? data["99"] : "99", page: data ? data["1"] : "1" });
        setcountSuccessProcessedSkip(false);
    };

    const totalPremiumTypeListData = (data) => {
        var formatedData = {
            ...totalPremiumTypeFormatter(data?.data || data, "sumPremiumTypeData"),
        };
        dispatch(setStore(formatedData));
    };

    const totalPremiumTypeSuccess = (data) => {
        totalPremiumTypeListData(data);
    };
    useEffect(() => {
        if (totalPremiumTypeQuery.isSuccess) {
            totalPremiumTypeSuccess(totalPremiumTypeQuery);
        } else if (totalPremiumTypeQuery.isError) {
            totalPremiumTypeSuccess(null);
        }
    }, [totalPremiumTypeQuery]);
    const totalPremiumType = (apiRespone) => {
        var data = apiRespone?.data || apiRespone;

        settotalPremiumTypeParams({
            metric: data ? data["id,transaction_amount,policy_type"] : "id,transaction_amount,policy_type",
            function: data ? data["count,sum,"] : "count,sum,",
            dimension: data ? data["policy_type"] : "policy_type",
        });
        settotalPremiumTypeSkip(false);
    };

    useEffect(() => {
        totalPremiumType();
        countSuccessProcessed();
        countAllProcessed();
    }, []);

    return (
        <div className="builder_wrapper">
            <MenuWidget data={{ title: "Home" }}>
                <PMGrid container={true} marginTop={2}>
                    <PMGrid container={true} justifyContent="right">
                        <PMButton variant="contained" label="Select Date Range" onClick={filterClick}></PMButton>
                    </PMGrid>
                    {filterState ? (
                        <FormWidget
                            submitButtonLabel="Apply Filter"
                            Onsubmit={policyUpdateApi}
                            fieldsets={[
                                {
                                    fields: [
                                        { label: "Start Date", name: "start_date", type: "date", required: true },
                                        { label: "End Date", name: "end_date", type: "date", required: true },
                                    ],
                                },
                            ]}
                        ></FormWidget>
                    ) : (
                        ""
                    )}

                    <PMGrid container={true} justifyContent="center">
                        <MetricWidget
                            xs={4}
                            metricIcon="two_wheeler"
                            header="Two Wheeler"
                            metricValueColor="primary"
                            metricSecValueColor="secondary"
                            metricIconColor="primary"
                            variant="h3"
                            metricValue={sumPremiumTypeData?.TW?.count || []}
                            metricSecValue={sumPremiumTypeData?.TW?.sum || []}
                        ></MetricWidget>

                        <MetricWidget
                            xs={4}
                            metricIcon="directions_car"
                            header="Four Wheeler"
                            metricValueColor="primary"
                            metricSecValueColor="secondary"
                            metricIconColor="primary"
                            variant="h3"
                            metricValue={sumPremiumTypeData?.FW?.count || []}
                            metricSecValue={sumPremiumTypeData?.FW?.sum || []}
                        ></MetricWidget>

                        <MetricWidget
                            xs={4}
                            metricIcon="local_shipping"
                            header="Commercial Vehicle"
                            metricValueColor="primary"
                            metricSecValueColor="secondary"
                            metricIconColor="primary"
                            variant="h3"
                            metricValue={sumPremiumTypeData?.CV?.count || []}
                            metricSecValue={sumPremiumTypeData?.CV?.sum || []}
                        ></MetricWidget>
                    </PMGrid>

                    <PMGrid container={true} sx={{ py: 2 }} justifyContent="center" updatedValue={insurerData || []}>
                        <DetailWidget
                            direction="row"
                            justifyContent="center"
                            xs={12}
                            fields={[
                                { label: "SBI", value: insurerData?.SBI?.total, subtext: insurerData?.SBI?.premium },
                                {
                                    label: "HDFC Ergo",
                                    value: insurerData?.HDFC_ERGO?.total,
                                    subtext: insurerData?.HDFC_ERGO?.premium,
                                },
                                {
                                    label: "ICICI Lombard",
                                    value: insurerData?.ICICI_LOMBARD?.total,
                                    subtext: insurerData?.ICICI_LOMBARD?.premium,
                                },
                                {
                                    label: "New India",
                                    value: insurerData?.NEW_INDIA?.total,
                                    subtext: insurerData?.NEW_INDIA?.premium,
                                },
                                {
                                    label: "Go Digit",
                                    value: insurerData?.DIGIT?.total,
                                    subtext: insurerData?.DIGIT?.premium,
                                },
                                {
                                    label: "United",
                                    value: insurerData?.UNITED?.total,
                                    subtext: insurerData?.UNITED?.premium,
                                },
                                {
                                    label: "Reliance",
                                    value: insurerData?.RELIANCE?.total,
                                    subtext: insurerData?.RELIANCE?.premium,
                                },
                                {
                                    label: "National",
                                    value: insurerData?.NATIONAL?.total,
                                    subtext: insurerData?.NATIONAL?.premium,
                                },
                            ]}
                        ></DetailWidget>
                    </PMGrid>
                    <PMGrid container={true} sx={{ py: 2 }} justifyContent="center" updatedValue={insurerData || []}>
                        <DetailWidget
                            direction="row"
                            justifyContent="center"
                            xs={12}
                            fields={[
                                { label: "Oriental", value: insurerData?.ORIENTAL?.total, subtext: insurerData?.ORIENTAL?.premium },
                                {
                                    label: "Royal Sundram",
                                    value: insurerData?.ROYAL_SUNDARAM?.total,
                                    subtext: insurerData?.ROYAL_SUNDARAM?.premium,
                                },
                                {
                                    label: "Liberty",
                                    value: insurerData?.LIBERTY?.total,
                                    subtext: insurerData?.LIBERTY?.premium,
                                },
                                {
                                    label: "Universal Sompo",
                                    value: insurerData?.UNIVERSAL_SOMPO?.total,
                                    subtext: insurerData?.UNIVERSAL_SOMPO?.premium,
                                },
                                {
                                    label: "Iffco Tokio",
                                    value: insurerData?.IFFCO_TOKIO?.total,
                                    subtext: insurerData?.IFFCO_TOKIO?.premium,
                                },
                                {
                                    label: "Future General",
                                    value: insurerData?.FUTURE_GENERAL?.total,
                                    subtext: insurerData?.FUTURE_GENERAL?.premium,
                                },
                                {
                                    label: "Magma HDI",
                                    value: insurerData?.MAGMA_HDI?.total,
                                    subtext: insurerData?.MAGMA_HDI?.premium,
                                },
                            ]}
                        ></DetailWidget>
                    </PMGrid>
                </PMGrid>

                <PMGrid container={true} sx={{ my: 2 }}>
                    <TableWidget
                        header="Date-wise Summary(Last 10)"
                        showQuickFilter={false}
                        showSerialNumber={true}
                        xs={12}
                        rows={tableDataSucc || []}
                        columns={[
                            { field: "date", headerName: "Date", type: "date" },
                            { field: "uploaded", headerName: "Total PDFs Uploaded" },
                            { field: "success", headerName: "Successfully Parsed", highlight: true },
                            { field: "error", headerName: "Errors" },
                            { field: "success_per", headerName: "Success%" },
                        ]}
                    ></TableWidget>
                </PMGrid>
            </MenuWidget>
        </div>
    );
}
export default Home;
