import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const policyUpdateSlice = createApi({
    reducerPath: "policyUpdate",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://policy-parsing.demo.permute.in",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("authToken");
            if (token) {
                headers.set("authorization", "Bearer " + token);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        policyUpdate: builder.mutation({
            query: ({ id, data }) => ({
                url: "/policy-inward/api/v1/policy/update?id=" + id,
                method: "PATCH",
                body: data,
            }),
        }),
    }),
});

export const { usePolicyUpdateMutation } = policyUpdateSlice;
